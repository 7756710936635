

























import { Component, Vue } from 'vue-property-decorator';

import LoaderViewModel from '@/vue-app/view-models/components/custom/loader-view-model';

@Component({ name: 'Loader' })
export default class FlagshipProfilingStepProcess extends Vue {
  loader_view_model = Vue.observable(new LoaderViewModel());
}
